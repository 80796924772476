import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/node/work/src/templates/post.template.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const SmallButton = makeShortcode("SmallButton");
const MediumButton = makeShortcode("MediumButton");
const LargeButton = makeShortcode("LargeButton");
const Button = makeShortcode("Button");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "headers"
    }}>{`Headers`}</h2>
    <h1 {...{
      "id": "h1"
    }}>{`H1`}</h1>
    <p>{`It is recommended to NOT use H1s as it is reserved for the article heading. Any H1 is set as an H2.`}</p>
    <h2 {...{
      "id": "h2"
    }}>{`H2`}</h2>
    <h3 {...{
      "id": "h3"
    }}>{`H3`}</h3>
    <h4 {...{
      "id": "h4"
    }}>{`H4`}</h4>
    <h5 {...{
      "id": "h5"
    }}>{`H5`}</h5>
    <h6 {...{
      "id": "h6"
    }}>{`H6`}</h6>
    <h2 {...{
      "id": "emphasis"
    }}>{`Emphasis`}</h2>
    <p>{`Emphasis, aka italics, with `}<em parentName="p">{`asterisks`}</em>{` or `}<em parentName="p">{`underscores`}</em>{`.`}</p>
    <p>{`Strong emphasis, aka bold, with `}<strong parentName="p">{`asterisks`}</strong>{` or `}<strong parentName="p">{`underscores`}</strong>{`.`}</p>
    <p>{`Combined emphasis with `}<strong parentName="p"><em parentName="strong">{`asterisks`}</em>{` and `}<em parentName="strong">{`underscores`}</em></strong>{`.`}</p>
    <p>{`Strikethrough uses two tildes. `}<del parentName="p">{`Scratch this.`}</del></p>
    <h2 {...{
      "id": "lists"
    }}>{`Lists`}</h2>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`First ordered list item`}</p>
        <p parentName="li">{`yay`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Another item`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Actual numbers don't matter, just that it's a number`}</p>
        <ol parentName="li">
          <li parentName="ol">
            <p parentName="li">{`Gotta`}</p>
            <ol parentName="li">
              <li parentName="ol">
                <p parentName="li">{`Love`}</p>
                <p parentName="li">{`woo`}</p>
                <ol parentName="li">
                  <li parentName="ol">
                    <p parentName="li">{`That`}</p>
                    <ol parentName="li">
                      <li parentName="ol">{`Nesting`}</li>
                    </ol>
                  </li>
                </ol>
              </li>
            </ol>
          </li>
          <li parentName="ol">
            <p parentName="li">{`fancy`}</p>
          </li>
        </ol>
      </li>
    </ol>
    <hr></hr>
    <h2 {...{
      "id": "unordered-lists"
    }}>{`Unordered Lists`}</h2>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Unordered list example`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`testing`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`just make sure not to nest!`}</p>
        <ul parentName="li">
          <li parentName="ul">
            <p parentName="li">{`no`}</p>
            <ul parentName="li">
              <li parentName="ul">
                <p parentName="li">{`nesting`}</p>
                <p parentName="li">{`not that pretty`}</p>
              </li>
            </ul>
          </li>
        </ul>
      </li>
    </ul>
    <h2 {...{
      "id": "mixing-them-together"
    }}>{`Mixing them together`}</h2>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`First ordered list item`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`wow`}</p>
        <p parentName="li">{`this is a sentence!`}</p>
        <ul parentName="li">
          <li parentName="ul">
            <p parentName="li">{`asdf`}</p>
          </li>
          <li parentName="ul">
            <p parentName="li">{`asdf`}</p>
          </li>
        </ul>
      </li>
    </ol>
    <h2 {...{
      "id": "links"
    }}>{`Links`}</h2>
    <p><a parentName="p" {...{
        "href": "https://www.google.com"
      }}>{`I'm an inline-style link`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://www.google.com",
        "title": "Google's Homepage"
      }}>{`I'm an inline-style link with title`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://www.mozilla.org"
      }}>{`I'm a reference-style link`}</a></p>
    <p><a parentName="p" {...{
        "href": "../blob/master/LICENSE"
      }}>{`I'm a relative reference to a repository file`}</a></p>
    <p><a parentName="p" {...{
        "href": "http://slashdot.org"
      }}>{`You can use numbers for reference-style link definitions`}</a></p>
    <p>{`Or leave it empty and use the `}<a parentName="p" {...{
        "href": "http://www.reddit.com"
      }}>{`link text itself`}</a>{`.`}</p>
    <p>{`URLs and URLs in angle brackets will automatically get turned into links.
`}<a parentName="p" {...{
        "href": "http://www.example.com"
      }}>{`http://www.example.com`}</a>{` or `}<a parentName="p" {...{
        "href": "http://www.example.com"
      }}>{`http://www.example.com`}</a>{` and sometimes
example.com (but not on Github, for example).`}</p>
    <p>{`Some text to show that the reference links can follow later.`}</p>
    <h2 {...{
      "id": "images"
    }}>{`Images`}</h2>
    <div {...{
      "className": "Image__Small"
    }}>{`
  `}<span parentName="div" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1360px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "100%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAGQABAAMBAQAAAAAAAAAAAAAAAAIDBAEF/8QAFwEBAQEBAAAAAAAAAAAAAAAAAQIAA//aAAwDAQACEAMQAAAB9aPaHlNAXlpCtIx//8QAHBAAAgICAwAAAAAAAAAAAAAAAAIBAxAREiJB/9oACAEBAAEFAsSe8zTktL2P1da1P//EABkRAAEFAAAAAAAAAAAAAAAAAAEAAhAREv/aAAgBAwEBPwENtZE//8QAFhEBAQEAAAAAAAAAAAAAAAAAEQAQ/9oACAECAQE/AWd//8QAIhAAAgEBCAMAAAAAAAAAAAAAAAEhAgMQERIxMkFRYXHh/9oACAEBAAY/ArvhhyJbn0Taqnwh0aUroWWIJn2f/8QAHRABAAIDAQADAAAAAAAAAAAAAQARITFBcWGBkf/aAAgBAQABPyFizbDfiZZ0+BAJkXnX3Hpf5RbORjrHYlC1mPIK6Xvan//aAAwDAQACAAMAAAAQnzdD/8QAGBEAAgMAAAAAAAAAAAAAAAAAAAEQIWH/2gAIAQMBAT8QZZmIo//EABgRAAMBAQAAAAAAAAAAAAAAAAABERAx/9oACAECAQE/EHHCh5//xAAeEAEAAgIDAAMAAAAAAAAAAAABABEhMUFRkWGBsf/aAAgBAQABPxBQjfKHsDtDs30iRdlmq0M5ejHMNYZekPa5/Ja+NaB7FCkWuwqtt0RHSVLrYv7YEVyLF6Oqn//Z')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/26392bc54452647c06d65c034ab374d7/2035a/article-image-2.webp 1360w"],
            "sizes": "(max-width: 1360px) 100vw, 1360px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/26392bc54452647c06d65c034ab374d7/68efc/article-image-2.jpg 1360w"],
            "sizes": "(max-width: 1360px) 100vw, 1360px",
            "type": "image/jpeg"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/26392bc54452647c06d65c034ab374d7/68efc/article-image-2.jpg",
            "alt": "Alt text",
            "title": "Logo Title Text 1",
            "loading": "lazy",
            "decoding": "async",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span>
    </div>
    <p>{`Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum. Lorem Ipsum is simply dummy text of the printing and typesetting industry.`}</p>
    <h2 {...{
      "id": "code-and-syntax-highlighting"
    }}>{`Code and Syntax Highlighting`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`var s = "JavaScript syntax highlighting";
alert(s);
`}</code></pre>
    <pre><code parentName="pre" {...{}}>{`No language indicated, so no syntax highlighting.
But let's throw in a <b>tag</b>.
`}</code></pre>
    <h3 {...{
      "id": "jsx"
    }}>{`JSX`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import React from "react";
import { ThemeProvider } from "theme-ui";
import theme from "./theme";

export default props => (
  <ThemeProvider theme={theme}>{props.children}</ThemeProvider>
);
`}</code></pre>
    <h2 {...{
      "id": "latex"
    }}>{`Latex`}</h2>
    <p>{`Here are some nifty inline formulas`}</p>
    <p><span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><msup parentName="mrow"><mi parentName="msup">{`a`}</mi><mn parentName="msup">{`2`}</mn></msup><mo parentName="mrow">{`+`}</mo><msup parentName="mrow"><mi parentName="msup">{`b`}</mi><mn parentName="msup">{`2`}</mn></msup><mo parentName="mrow">{`=`}</mo><msup parentName="mrow"><mi parentName="msup">{`c`}</mi><mn parentName="msup">{`2`}</mn></msup><mspace parentName="mrow" {...{
                    "linebreak": "newline"
                  }}></mspace><mi parentName="mrow">{`f`}</mi><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`(`}</mo><mi parentName="mrow">{`x`}</mi><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`)`}</mo><mo parentName="mrow">{`=`}</mo><msubsup parentName="mrow"><mo parentName="msubsup">{`∫`}</mo><mrow parentName="msubsup"><mo parentName="mrow">{`−`}</mo><mi parentName="mrow" {...{
                        "mathvariant": "normal"
                      }}>{`∞`}</mi></mrow><mi parentName="msubsup" {...{
                      "mathvariant": "normal"
                    }}>{`∞`}</mi></msubsup><mover parentName="mrow" {...{
                    "accent": "true"
                  }}><mi parentName="mover">{`f`}</mi><mo parentName="mover">{`^`}</mo></mover><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`(`}</mo><mi parentName="mrow">{`ξ`}</mi><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`)`}</mo><mtext parentName="mrow">{` `}</mtext><msup parentName="mrow"><mi parentName="msup">{`e`}</mi><mrow parentName="msup"><mn parentName="mrow">{`2`}</mn><mi parentName="mrow">{`π`}</mi><mi parentName="mrow">{`i`}</mi><mi parentName="mrow">{`ξ`}</mi><mi parentName="mrow">{`x`}</mi></mrow></msup><mtext parentName="mrow">{` `}</mtext><mi parentName="mrow">{`d`}</mi><mi parentName="mrow">{`ξ`}</mi></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`a^2 + b^2 = c^2 \\newline f(x) = \\int_{-\\infty}^\\infty\\hat f(\\xi)\\,e^{2 \\pi i \\xi x}\\,d\\xi`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.8974em",
                  "verticalAlign": "-0.0833em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mord mathnormal"
                }}>{`a`}</span><span parentName="span" {...{
                  "className": "msupsub"
                }}><span parentName="span" {...{
                    "className": "vlist-t"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.8141em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-3.063em",
                            "marginRight": "0.05em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "2.7em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mtight"
                            }}>{`2`}</span></span></span></span></span></span></span></span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2222em"
                }
              }}></span><span parentName="span" {...{
                "className": "mbin"
              }}>{`+`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2222em"
                }
              }}></span></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.8141em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mord mathnormal"
                }}>{`b`}</span><span parentName="span" {...{
                  "className": "msupsub"
                }}><span parentName="span" {...{
                    "className": "vlist-t"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.8141em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-3.063em",
                            "marginRight": "0.05em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "2.7em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mtight"
                            }}>{`2`}</span></span></span></span></span></span></span></span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span><span parentName="span" {...{
                "className": "mrel"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.8141em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mord mathnormal"
                }}>{`c`}</span><span parentName="span" {...{
                  "className": "msupsub"
                }}><span parentName="span" {...{
                    "className": "vlist-t"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.8141em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-3.063em",
                            "marginRight": "0.05em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "2.7em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mtight"
                            }}>{`2`}</span></span></span></span></span></span></span></span></span><span parentName="span" {...{
              "className": "mspace newline"
            }}></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "1em",
                  "verticalAlign": "-0.25em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.10764em"
                }
              }}>{`f`}</span><span parentName="span" {...{
                "className": "mopen"
              }}>{`(`}</span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`x`}</span><span parentName="span" {...{
                "className": "mclose"
              }}>{`)`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span><span parentName="span" {...{
                "className": "mrel"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "1.372em",
                  "verticalAlign": "-0.4142em"
                }
              }}></span><span parentName="span" {...{
                "className": "mop"
              }}><span parentName="span" {...{
                  "className": "mop op-symbol small-op",
                  "style": {
                    "marginRight": "0.19445em",
                    "position": "relative",
                    "top": "-0.0006em"
                  }
                }}>{`∫`}</span><span parentName="span" {...{
                  "className": "msupsub"
                }}><span parentName="span" {...{
                    "className": "vlist-t vlist-t2"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.8593em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-2.3442em",
                            "marginLeft": "-0.1945em",
                            "marginRight": "0.05em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "2.7em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mtight"
                            }}><span parentName="span" {...{
                                "className": "mord mtight"
                              }}>{`−`}</span><span parentName="span" {...{
                                "className": "mord mtight"
                              }}>{`∞`}</span></span></span></span><span parentName="span" {...{
                          "style": {
                            "top": "-3.2579em",
                            "marginRight": "0.05em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "2.7em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mtight"
                            }}>{`∞`}</span></span></span></span><span parentName="span" {...{
                        "className": "vlist-s"
                      }}>{`​`}</span></span><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.4142em"
                        }
                      }}><span parentName="span"></span></span></span></span></span></span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.1667em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord accent"
              }}><span parentName="span" {...{
                  "className": "vlist-t vlist-t2"
                }}><span parentName="span" {...{
                    "className": "vlist-r"
                  }}><span parentName="span" {...{
                      "className": "vlist",
                      "style": {
                        "height": "0.9579em"
                      }
                    }}><span parentName="span" {...{
                        "style": {
                          "top": "-3em"
                        }
                      }}><span parentName="span" {...{
                          "className": "pstrut",
                          "style": {
                            "height": "3em"
                          }
                        }}></span><span parentName="span" {...{
                          "className": "mord mathnormal",
                          "style": {
                            "marginRight": "0.10764em"
                          }
                        }}>{`f`}</span></span><span parentName="span" {...{
                        "style": {
                          "top": "-3.2634em"
                        }
                      }}><span parentName="span" {...{
                          "className": "pstrut",
                          "style": {
                            "height": "3em"
                          }
                        }}></span><span parentName="span" {...{
                          "className": "accent-body",
                          "style": {
                            "left": "-0.0833em"
                          }
                        }}><span parentName="span" {...{
                            "className": "mord"
                          }}>{`^`}</span></span></span></span><span parentName="span" {...{
                      "className": "vlist-s"
                    }}>{`​`}</span></span><span parentName="span" {...{
                    "className": "vlist-r"
                  }}><span parentName="span" {...{
                      "className": "vlist",
                      "style": {
                        "height": "0.1944em"
                      }
                    }}><span parentName="span"></span></span></span></span></span><span parentName="span" {...{
                "className": "mopen"
              }}>{`(`}</span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.04601em"
                }
              }}>{`ξ`}</span><span parentName="span" {...{
                "className": "mclose"
              }}>{`)`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.1667em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mord mathnormal"
                }}>{`e`}</span><span parentName="span" {...{
                  "className": "msupsub"
                }}><span parentName="span" {...{
                    "className": "vlist-t"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.8491em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-3.063em",
                            "marginRight": "0.05em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "2.7em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mtight"
                            }}><span parentName="span" {...{
                                "className": "mord mtight"
                              }}>{`2`}</span><span parentName="span" {...{
                                "className": "mord mathnormal mtight"
                              }}>{`πi`}</span><span parentName="span" {...{
                                "className": "mord mathnormal mtight",
                                "style": {
                                  "marginRight": "0.04601em"
                                }
                              }}>{`ξ`}</span><span parentName="span" {...{
                                "className": "mord mathnormal mtight"
                              }}>{`x`}</span></span></span></span></span></span></span></span></span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.1667em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`d`}</span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.04601em"
                }
              }}>{`ξ`}</span></span></span></span></span></p>
    <p>{`systems of equations 🤓`}</p>
    <p><span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mtable parentName="semantics" {...{
                  "rowspacing": "0.25em",
                  "columnalign": "right left right left",
                  "columnspacing": "0em"
                }}><mtr parentName="mtable"><mtd parentName="mtr"><mstyle parentName="mtd" {...{
                        "scriptlevel": "0",
                        "displaystyle": "true"
                      }}><mn parentName="mstyle">{`10`}</mn></mstyle></mtd><mtd parentName="mtr"><mstyle parentName="mtd" {...{
                        "scriptlevel": "0",
                        "displaystyle": "true"
                      }}><mrow parentName="mstyle"><mrow parentName="mrow"></mrow><mi parentName="mrow">{`x`}</mi><mo parentName="mrow">{`+`}</mo></mrow></mstyle></mtd><mtd parentName="mtr"><mstyle parentName="mtd" {...{
                        "scriptlevel": "0",
                        "displaystyle": "true"
                      }}><mn parentName="mstyle">{`3`}</mn></mstyle></mtd><mtd parentName="mtr"><mstyle parentName="mtd" {...{
                        "scriptlevel": "0",
                        "displaystyle": "true"
                      }}><mrow parentName="mstyle"><mrow parentName="mrow"></mrow><mi parentName="mrow">{`y`}</mi><mo parentName="mrow">{`=`}</mo><mn parentName="mrow">{`2`}</mn></mrow></mstyle></mtd></mtr><mtr parentName="mtable"><mtd parentName="mtr"><mstyle parentName="mtd" {...{
                        "scriptlevel": "0",
                        "displaystyle": "true"
                      }}><mn parentName="mstyle">{`3`}</mn></mstyle></mtd><mtd parentName="mtr"><mstyle parentName="mtd" {...{
                        "scriptlevel": "0",
                        "displaystyle": "true"
                      }}><mrow parentName="mstyle"><mrow parentName="mrow"></mrow><mi parentName="mrow">{`x`}</mi><mo parentName="mrow">{`+`}</mo></mrow></mstyle></mtd><mtd parentName="mtr"><mstyle parentName="mtd" {...{
                        "scriptlevel": "0",
                        "displaystyle": "true"
                      }}><mn parentName="mstyle">{`13`}</mn></mstyle></mtd><mtd parentName="mtr"><mstyle parentName="mtd" {...{
                        "scriptlevel": "0",
                        "displaystyle": "true"
                      }}><mrow parentName="mstyle"><mrow parentName="mrow"></mrow><mi parentName="mrow">{`y`}</mi><mo parentName="mrow">{`=`}</mo><mn parentName="mrow">{`4`}</mn></mrow></mstyle></mtd></mtr></mtable><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`\\begin{alignedat}{2}  10&x+ &3&y = 2 \\\\  3&x+&13&y = 4 \\end{alignedat}`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "3em",
                  "verticalAlign": "-1.25em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mtable"
                }}><span parentName="span" {...{
                    "className": "col-align-r"
                  }}><span parentName="span" {...{
                      "className": "vlist-t vlist-t2"
                    }}><span parentName="span" {...{
                        "className": "vlist-r"
                      }}><span parentName="span" {...{
                          "className": "vlist",
                          "style": {
                            "height": "1.75em"
                          }
                        }}><span parentName="span" {...{
                            "style": {
                              "top": "-3.91em"
                            }
                          }}><span parentName="span" {...{
                              "className": "pstrut",
                              "style": {
                                "height": "3em"
                              }
                            }}></span><span parentName="span" {...{
                              "className": "mord"
                            }}><span parentName="span" {...{
                                "className": "mord"
                              }}>{`10`}</span></span></span><span parentName="span" {...{
                            "style": {
                              "top": "-2.41em"
                            }
                          }}><span parentName="span" {...{
                              "className": "pstrut",
                              "style": {
                                "height": "3em"
                              }
                            }}></span><span parentName="span" {...{
                              "className": "mord"
                            }}><span parentName="span" {...{
                                "className": "mord"
                              }}>{`3`}</span></span></span></span><span parentName="span" {...{
                          "className": "vlist-s"
                        }}>{`​`}</span></span><span parentName="span" {...{
                        "className": "vlist-r"
                      }}><span parentName="span" {...{
                          "className": "vlist",
                          "style": {
                            "height": "1.25em"
                          }
                        }}><span parentName="span"></span></span></span></span></span><span parentName="span" {...{
                    "className": "col-align-l"
                  }}><span parentName="span" {...{
                      "className": "vlist-t vlist-t2"
                    }}><span parentName="span" {...{
                        "className": "vlist-r"
                      }}><span parentName="span" {...{
                          "className": "vlist",
                          "style": {
                            "height": "1.75em"
                          }
                        }}><span parentName="span" {...{
                            "style": {
                              "top": "-3.91em"
                            }
                          }}><span parentName="span" {...{
                              "className": "pstrut",
                              "style": {
                                "height": "3em"
                              }
                            }}></span><span parentName="span" {...{
                              "className": "mord"
                            }}><span parentName="span" {...{
                                "className": "mord"
                              }}></span><span parentName="span" {...{
                                "className": "mord mathnormal"
                              }}>{`x`}</span><span parentName="span" {...{
                                "className": "mord"
                              }}>{`+`}</span></span></span><span parentName="span" {...{
                            "style": {
                              "top": "-2.41em"
                            }
                          }}><span parentName="span" {...{
                              "className": "pstrut",
                              "style": {
                                "height": "3em"
                              }
                            }}></span><span parentName="span" {...{
                              "className": "mord"
                            }}><span parentName="span" {...{
                                "className": "mord"
                              }}></span><span parentName="span" {...{
                                "className": "mord mathnormal"
                              }}>{`x`}</span><span parentName="span" {...{
                                "className": "mord"
                              }}>{`+`}</span></span></span></span><span parentName="span" {...{
                          "className": "vlist-s"
                        }}>{`​`}</span></span><span parentName="span" {...{
                        "className": "vlist-r"
                      }}><span parentName="span" {...{
                          "className": "vlist",
                          "style": {
                            "height": "1.25em"
                          }
                        }}><span parentName="span"></span></span></span></span></span><span parentName="span" {...{
                    "className": "col-align-r"
                  }}><span parentName="span" {...{
                      "className": "vlist-t vlist-t2"
                    }}><span parentName="span" {...{
                        "className": "vlist-r"
                      }}><span parentName="span" {...{
                          "className": "vlist",
                          "style": {
                            "height": "1.75em"
                          }
                        }}><span parentName="span" {...{
                            "style": {
                              "top": "-3.91em"
                            }
                          }}><span parentName="span" {...{
                              "className": "pstrut",
                              "style": {
                                "height": "3em"
                              }
                            }}></span><span parentName="span" {...{
                              "className": "mord"
                            }}><span parentName="span" {...{
                                "className": "mord"
                              }}>{`3`}</span></span></span><span parentName="span" {...{
                            "style": {
                              "top": "-2.41em"
                            }
                          }}><span parentName="span" {...{
                              "className": "pstrut",
                              "style": {
                                "height": "3em"
                              }
                            }}></span><span parentName="span" {...{
                              "className": "mord"
                            }}><span parentName="span" {...{
                                "className": "mord"
                              }}>{`13`}</span></span></span></span><span parentName="span" {...{
                          "className": "vlist-s"
                        }}>{`​`}</span></span><span parentName="span" {...{
                        "className": "vlist-r"
                      }}><span parentName="span" {...{
                          "className": "vlist",
                          "style": {
                            "height": "1.25em"
                          }
                        }}><span parentName="span"></span></span></span></span></span><span parentName="span" {...{
                    "className": "col-align-l"
                  }}><span parentName="span" {...{
                      "className": "vlist-t vlist-t2"
                    }}><span parentName="span" {...{
                        "className": "vlist-r"
                      }}><span parentName="span" {...{
                          "className": "vlist",
                          "style": {
                            "height": "1.75em"
                          }
                        }}><span parentName="span" {...{
                            "style": {
                              "top": "-3.91em"
                            }
                          }}><span parentName="span" {...{
                              "className": "pstrut",
                              "style": {
                                "height": "3em"
                              }
                            }}></span><span parentName="span" {...{
                              "className": "mord"
                            }}><span parentName="span" {...{
                                "className": "mord"
                              }}></span><span parentName="span" {...{
                                "className": "mord mathnormal",
                                "style": {
                                  "marginRight": "0.03588em"
                                }
                              }}>{`y`}</span><span parentName="span" {...{
                                "className": "mspace",
                                "style": {
                                  "marginRight": "0.2778em"
                                }
                              }}></span><span parentName="span" {...{
                                "className": "mrel"
                              }}>{`=`}</span><span parentName="span" {...{
                                "className": "mspace",
                                "style": {
                                  "marginRight": "0.2778em"
                                }
                              }}></span><span parentName="span" {...{
                                "className": "mord"
                              }}>{`2`}</span></span></span><span parentName="span" {...{
                            "style": {
                              "top": "-2.41em"
                            }
                          }}><span parentName="span" {...{
                              "className": "pstrut",
                              "style": {
                                "height": "3em"
                              }
                            }}></span><span parentName="span" {...{
                              "className": "mord"
                            }}><span parentName="span" {...{
                                "className": "mord"
                              }}></span><span parentName="span" {...{
                                "className": "mord mathnormal",
                                "style": {
                                  "marginRight": "0.03588em"
                                }
                              }}>{`y`}</span><span parentName="span" {...{
                                "className": "mspace",
                                "style": {
                                  "marginRight": "0.2778em"
                                }
                              }}></span><span parentName="span" {...{
                                "className": "mrel"
                              }}>{`=`}</span><span parentName="span" {...{
                                "className": "mspace",
                                "style": {
                                  "marginRight": "0.2778em"
                                }
                              }}></span><span parentName="span" {...{
                                "className": "mord"
                              }}>{`4`}</span></span></span></span><span parentName="span" {...{
                          "className": "vlist-s"
                        }}>{`​`}</span></span><span parentName="span" {...{
                        "className": "vlist-r"
                      }}><span parentName="span" {...{
                          "className": "vlist",
                          "style": {
                            "height": "1.25em"
                          }
                        }}><span parentName="span"></span></span></span></span></span></span></span></span></span></span></span></p>
    <div {...{
      "className": "math math-display"
    }}><span parentName="div" {...{
        "className": "katex-display"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML",
              "display": "block"
            }}><semantics parentName="math"><mtable parentName="semantics" {...{
                  "rowspacing": "0.25em",
                  "columnalign": "right left right left",
                  "columnspacing": "0em"
                }}><mtr parentName="mtable"><mtd parentName="mtr"><mstyle parentName="mtd" {...{
                        "scriptlevel": "0",
                        "displaystyle": "true"
                      }}><mn parentName="mstyle">{`6666`}</mn></mstyle></mtd><mtd parentName="mtr"><mstyle parentName="mtd" {...{
                        "scriptlevel": "0",
                        "displaystyle": "true"
                      }}><mrow parentName="mstyle"><mrow parentName="mrow"></mrow><mi parentName="mrow">{`x`}</mi><mo parentName="mrow">{`+`}</mo></mrow></mstyle></mtd><mtd parentName="mtr"><mstyle parentName="mtd" {...{
                        "scriptlevel": "0",
                        "displaystyle": "true"
                      }}><mn parentName="mstyle">{`3`}</mn></mstyle></mtd><mtd parentName="mtr"><mstyle parentName="mtd" {...{
                        "scriptlevel": "0",
                        "displaystyle": "true"
                      }}><mrow parentName="mstyle"><mrow parentName="mrow"></mrow><mi parentName="mrow">{`y`}</mi><mo parentName="mrow">{`=`}</mo><mn parentName="mrow">{`2`}</mn></mrow></mstyle></mtd></mtr><mtr parentName="mtable"><mtd parentName="mtr"><mstyle parentName="mtd" {...{
                        "scriptlevel": "0",
                        "displaystyle": "true"
                      }}><mn parentName="mstyle">{`3`}</mn></mstyle></mtd><mtd parentName="mtr"><mstyle parentName="mtd" {...{
                        "scriptlevel": "0",
                        "displaystyle": "true"
                      }}><mrow parentName="mstyle"><mrow parentName="mrow"></mrow><mi parentName="mrow">{`x`}</mi><mo parentName="mrow">{`+`}</mo></mrow></mstyle></mtd><mtd parentName="mtr"><mstyle parentName="mtd" {...{
                        "scriptlevel": "0",
                        "displaystyle": "true"
                      }}><mn parentName="mstyle">{`13`}</mn></mstyle></mtd><mtd parentName="mtr"><mstyle parentName="mtd" {...{
                        "scriptlevel": "0",
                        "displaystyle": "true"
                      }}><mrow parentName="mstyle"><mrow parentName="mrow"></mrow><mi parentName="mrow">{`y`}</mi><mo parentName="mrow">{`=`}</mo><mn parentName="mrow">{`4`}</mn></mrow></mstyle></mtd></mtr></mtable><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`\\begin{alignedat}{2} 6666&x+ &3&y = 2 \\\\ 3&x+&13&y = 4 \\end{alignedat}`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "3em",
                  "verticalAlign": "-1.25em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mtable"
                }}><span parentName="span" {...{
                    "className": "col-align-r"
                  }}><span parentName="span" {...{
                      "className": "vlist-t vlist-t2"
                    }}><span parentName="span" {...{
                        "className": "vlist-r"
                      }}><span parentName="span" {...{
                          "className": "vlist",
                          "style": {
                            "height": "1.75em"
                          }
                        }}><span parentName="span" {...{
                            "style": {
                              "top": "-3.91em"
                            }
                          }}><span parentName="span" {...{
                              "className": "pstrut",
                              "style": {
                                "height": "3em"
                              }
                            }}></span><span parentName="span" {...{
                              "className": "mord"
                            }}><span parentName="span" {...{
                                "className": "mord"
                              }}>{`6666`}</span></span></span><span parentName="span" {...{
                            "style": {
                              "top": "-2.41em"
                            }
                          }}><span parentName="span" {...{
                              "className": "pstrut",
                              "style": {
                                "height": "3em"
                              }
                            }}></span><span parentName="span" {...{
                              "className": "mord"
                            }}><span parentName="span" {...{
                                "className": "mord"
                              }}>{`3`}</span></span></span></span><span parentName="span" {...{
                          "className": "vlist-s"
                        }}>{`​`}</span></span><span parentName="span" {...{
                        "className": "vlist-r"
                      }}><span parentName="span" {...{
                          "className": "vlist",
                          "style": {
                            "height": "1.25em"
                          }
                        }}><span parentName="span"></span></span></span></span></span><span parentName="span" {...{
                    "className": "col-align-l"
                  }}><span parentName="span" {...{
                      "className": "vlist-t vlist-t2"
                    }}><span parentName="span" {...{
                        "className": "vlist-r"
                      }}><span parentName="span" {...{
                          "className": "vlist",
                          "style": {
                            "height": "1.75em"
                          }
                        }}><span parentName="span" {...{
                            "style": {
                              "top": "-3.91em"
                            }
                          }}><span parentName="span" {...{
                              "className": "pstrut",
                              "style": {
                                "height": "3em"
                              }
                            }}></span><span parentName="span" {...{
                              "className": "mord"
                            }}><span parentName="span" {...{
                                "className": "mord"
                              }}></span><span parentName="span" {...{
                                "className": "mord mathnormal"
                              }}>{`x`}</span><span parentName="span" {...{
                                "className": "mord"
                              }}>{`+`}</span></span></span><span parentName="span" {...{
                            "style": {
                              "top": "-2.41em"
                            }
                          }}><span parentName="span" {...{
                              "className": "pstrut",
                              "style": {
                                "height": "3em"
                              }
                            }}></span><span parentName="span" {...{
                              "className": "mord"
                            }}><span parentName="span" {...{
                                "className": "mord"
                              }}></span><span parentName="span" {...{
                                "className": "mord mathnormal"
                              }}>{`x`}</span><span parentName="span" {...{
                                "className": "mord"
                              }}>{`+`}</span></span></span></span><span parentName="span" {...{
                          "className": "vlist-s"
                        }}>{`​`}</span></span><span parentName="span" {...{
                        "className": "vlist-r"
                      }}><span parentName="span" {...{
                          "className": "vlist",
                          "style": {
                            "height": "1.25em"
                          }
                        }}><span parentName="span"></span></span></span></span></span><span parentName="span" {...{
                    "className": "col-align-r"
                  }}><span parentName="span" {...{
                      "className": "vlist-t vlist-t2"
                    }}><span parentName="span" {...{
                        "className": "vlist-r"
                      }}><span parentName="span" {...{
                          "className": "vlist",
                          "style": {
                            "height": "1.75em"
                          }
                        }}><span parentName="span" {...{
                            "style": {
                              "top": "-3.91em"
                            }
                          }}><span parentName="span" {...{
                              "className": "pstrut",
                              "style": {
                                "height": "3em"
                              }
                            }}></span><span parentName="span" {...{
                              "className": "mord"
                            }}><span parentName="span" {...{
                                "className": "mord"
                              }}>{`3`}</span></span></span><span parentName="span" {...{
                            "style": {
                              "top": "-2.41em"
                            }
                          }}><span parentName="span" {...{
                              "className": "pstrut",
                              "style": {
                                "height": "3em"
                              }
                            }}></span><span parentName="span" {...{
                              "className": "mord"
                            }}><span parentName="span" {...{
                                "className": "mord"
                              }}>{`13`}</span></span></span></span><span parentName="span" {...{
                          "className": "vlist-s"
                        }}>{`​`}</span></span><span parentName="span" {...{
                        "className": "vlist-r"
                      }}><span parentName="span" {...{
                          "className": "vlist",
                          "style": {
                            "height": "1.25em"
                          }
                        }}><span parentName="span"></span></span></span></span></span><span parentName="span" {...{
                    "className": "col-align-l"
                  }}><span parentName="span" {...{
                      "className": "vlist-t vlist-t2"
                    }}><span parentName="span" {...{
                        "className": "vlist-r"
                      }}><span parentName="span" {...{
                          "className": "vlist",
                          "style": {
                            "height": "1.75em"
                          }
                        }}><span parentName="span" {...{
                            "style": {
                              "top": "-3.91em"
                            }
                          }}><span parentName="span" {...{
                              "className": "pstrut",
                              "style": {
                                "height": "3em"
                              }
                            }}></span><span parentName="span" {...{
                              "className": "mord"
                            }}><span parentName="span" {...{
                                "className": "mord"
                              }}></span><span parentName="span" {...{
                                "className": "mord mathnormal",
                                "style": {
                                  "marginRight": "0.03588em"
                                }
                              }}>{`y`}</span><span parentName="span" {...{
                                "className": "mspace",
                                "style": {
                                  "marginRight": "0.2778em"
                                }
                              }}></span><span parentName="span" {...{
                                "className": "mrel"
                              }}>{`=`}</span><span parentName="span" {...{
                                "className": "mspace",
                                "style": {
                                  "marginRight": "0.2778em"
                                }
                              }}></span><span parentName="span" {...{
                                "className": "mord"
                              }}>{`2`}</span></span></span><span parentName="span" {...{
                            "style": {
                              "top": "-2.41em"
                            }
                          }}><span parentName="span" {...{
                              "className": "pstrut",
                              "style": {
                                "height": "3em"
                              }
                            }}></span><span parentName="span" {...{
                              "className": "mord"
                            }}><span parentName="span" {...{
                                "className": "mord"
                              }}></span><span parentName="span" {...{
                                "className": "mord mathnormal",
                                "style": {
                                  "marginRight": "0.03588em"
                                }
                              }}>{`y`}</span><span parentName="span" {...{
                                "className": "mspace",
                                "style": {
                                  "marginRight": "0.2778em"
                                }
                              }}></span><span parentName="span" {...{
                                "className": "mrel"
                              }}>{`=`}</span><span parentName="span" {...{
                                "className": "mspace",
                                "style": {
                                  "marginRight": "0.2778em"
                                }
                              }}></span><span parentName="span" {...{
                                "className": "mord"
                              }}>{`4`}</span></span></span></span><span parentName="span" {...{
                          "className": "vlist-s"
                        }}>{`​`}</span></span><span parentName="span" {...{
                        "className": "vlist-r"
                      }}><span parentName="span" {...{
                          "className": "vlist",
                          "style": {
                            "height": "1.25em"
                          }
                        }}><span parentName="span"></span></span></span></span></span></span></span></span></span></span></span></div>
    <p>{`Here are some `}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mtext parentName="mrow">{`KaTeX`}</mtext></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`\\KaTeX`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.8988em",
                  "verticalAlign": "-0.2155em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord text"
              }}><span parentName="span" {...{
                  "className": "mord textrm"
                }}>{`K`}</span><span parentName="span" {...{
                  "className": "mspace",
                  "style": {
                    "marginRight": "-0.17em"
                  }
                }}></span><span parentName="span" {...{
                  "className": "vlist-t"
                }}><span parentName="span" {...{
                    "className": "vlist-r"
                  }}><span parentName="span" {...{
                      "className": "vlist",
                      "style": {
                        "height": "0.6833em"
                      }
                    }}><span parentName="span" {...{
                        "style": {
                          "top": "-2.905em"
                        }
                      }}><span parentName="span" {...{
                          "className": "pstrut",
                          "style": {
                            "height": "2.7em"
                          }
                        }}></span><span parentName="span" {...{
                          "className": "mord"
                        }}><span parentName="span" {...{
                            "className": "mord textrm mtight sizing reset-size6 size3"
                          }}>{`A`}</span></span></span></span></span></span><span parentName="span" {...{
                  "className": "mspace",
                  "style": {
                    "marginRight": "-0.15em"
                  }
                }}></span><span parentName="span" {...{
                  "className": "mord text"
                }}><span parentName="span" {...{
                    "className": "mord textrm"
                  }}>{`T`}</span><span parentName="span" {...{
                    "className": "mspace",
                    "style": {
                      "marginRight": "-0.1667em"
                    }
                  }}></span><span parentName="span" {...{
                    "className": "vlist-t vlist-t2"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.4678em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-2.7845em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "3em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "mord"
                          }}><span parentName="span" {...{
                              "className": "mord textrm"
                            }}>{`E`}</span></span></span></span><span parentName="span" {...{
                        "className": "vlist-s"
                      }}>{`​`}</span></span><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.2155em"
                        }
                      }}><span parentName="span"></span></span></span></span><span parentName="span" {...{
                    "className": "mspace",
                    "style": {
                      "marginRight": "-0.125em"
                    }
                  }}></span><span parentName="span" {...{
                    "className": "mord textrm"
                  }}>{`X`}</span></span></span></span></span></span></span>{` in display mode`}</p>
    <div {...{
      "className": "math math-display"
    }}><span parentName="div" {...{
        "className": "katex-display"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML",
              "display": "block"
            }}><semantics parentName="math"><mtable parentName="semantics" {...{
                  "rowspacing": "0.25em",
                  "columnalign": "center center center",
                  "columnspacing": "0.5em"
                }}><mtr parentName="mtable"><mtd parentName="mtr"><mstyle parentName="mtd" {...{
                        "scriptlevel": "0",
                        "displaystyle": "true"
                      }}><mi parentName="mstyle">{`A`}</mi></mstyle></mtd><mtd parentName="mtr"><mstyle parentName="mtd" {...{
                        "scriptlevel": "0",
                        "displaystyle": "true"
                      }}><munderover parentName="mstyle"><mo parentName="munderover" {...{
                            "stretchy": "true",
                            "minsize": "3.0em"
                          }}>{`→`}</mo><mpadded parentName="munderover" {...{
                            "width": "+0.6em",
                            "lspace": "0.3em"
                          }}><mrow parentName="mpadded"></mrow></mpadded><mpadded parentName="munderover" {...{
                            "width": "+0.6em",
                            "lspace": "0.3em"
                          }}><mi parentName="mpadded">{`a`}</mi></mpadded></munderover></mstyle></mtd><mtd parentName="mtr"><mstyle parentName="mtd" {...{
                        "scriptlevel": "0",
                        "displaystyle": "true"
                      }}><mi parentName="mstyle">{`B`}</mi></mstyle></mtd></mtr><mtr parentName="mtable"><mtd parentName="mtr"><mstyle parentName="mtd" {...{
                        "scriptlevel": "0",
                        "displaystyle": "true"
                      }}><mrow parentName="mstyle"><mrow parentName="mrow"><mstyle parentName="mrow" {...{
                              "displaystyle": "false",
                              "scriptlevel": "1"
                            }}><mpadded parentName="mstyle" {...{
                                "width": 0,
                                "lspace": "-1width",
                                "voffset": "0.7em"
                              }}><mrow parentName="mpadded"><mi parentName="mrow">{`b`}</mi></mrow></mpadded></mstyle><mo parentName="mrow" {...{
                              "fence": "false",
                              "stretchy": "true",
                              "minsize": "1.8em",
                              "maxsize": "1.8em"
                            }}>{`↓`}</mo><mstyle parentName="mrow" {...{
                              "displaystyle": "false",
                              "scriptlevel": "1"
                            }}><mpadded parentName="mstyle" {...{
                                "width": 0,
                                "voffset": "0.7em"
                              }}><mrow parentName="mpadded"><mrow parentName="mrow"></mrow></mrow></mpadded></mstyle></mrow></mrow></mstyle></mtd><mtd parentName="mtr"><mstyle parentName="mtd" {...{
                        "scriptlevel": "0",
                        "displaystyle": "true"
                      }}></mstyle></mtd><mtd parentName="mtr"><mstyle parentName="mtd" {...{
                        "scriptlevel": "0",
                        "displaystyle": "true"
                      }}><mrow parentName="mstyle"><mrow parentName="mrow"><mstyle parentName="mrow" {...{
                              "displaystyle": "false",
                              "scriptlevel": "1"
                            }}><mpadded parentName="mstyle" {...{
                                "width": 0,
                                "lspace": "-1width",
                                "voffset": "0.7em"
                              }}><mrow parentName="mpadded"><mrow parentName="mrow"></mrow></mrow></mpadded></mstyle><mo parentName="mrow" {...{
                              "fence": "false",
                              "stretchy": "true",
                              "minsize": "1.8em",
                              "maxsize": "1.8em"
                            }}>{`↑`}</mo><mstyle parentName="mrow" {...{
                              "displaystyle": "false",
                              "scriptlevel": "1"
                            }}><mpadded parentName="mstyle" {...{
                                "width": 0,
                                "voffset": "0.7em"
                              }}><mrow parentName="mpadded"><mi parentName="mrow">{`c`}</mi></mrow></mpadded></mstyle></mrow></mrow></mstyle></mtd></mtr><mtr parentName="mtable"><mtd parentName="mtr"><mstyle parentName="mtd" {...{
                        "scriptlevel": "0",
                        "displaystyle": "true"
                      }}><mi parentName="mstyle">{`C`}</mi></mstyle></mtd><mtd parentName="mtr"><mstyle parentName="mtd" {...{
                        "scriptlevel": "0",
                        "displaystyle": "true"
                      }}><mover parentName="mstyle"><mo parentName="mover" {...{
                            "stretchy": "true",
                            "minsize": "3.0em"
                          }}>{`=`}</mo><mpadded parentName="mover" {...{
                            "width": "+0.6em",
                            "lspace": "0.3em"
                          }}></mpadded></mover></mstyle></mtd><mtd parentName="mtr"><mstyle parentName="mtd" {...{
                        "scriptlevel": "0",
                        "displaystyle": "true"
                      }}><mi parentName="mstyle">{`D`}</mi></mstyle></mtd></mtr><mtr parentName="mtable"></mtr></mtable><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`\\begin{CD} A @>a>> B \\\\ @VbVV @AAcA \\\\ C @= D \\end{CD}`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "5.3053em",
                  "verticalAlign": "-1.6062em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mtable"
                }}><span parentName="span" {...{
                    "className": "col-align-c"
                  }}><span parentName="span" {...{
                      "className": "vlist-t vlist-t2"
                    }}><span parentName="span" {...{
                        "className": "vlist-r"
                      }}><span parentName="span" {...{
                          "className": "vlist",
                          "style": {
                            "height": "3.6992em"
                          }
                        }}><span parentName="span" {...{
                            "style": {
                              "top": "-5.9268em"
                            }
                          }}><span parentName="span" {...{
                              "className": "pstrut",
                              "style": {
                                "height": "3.151em"
                              }
                            }}></span><span parentName="span" {...{
                              "className": "mord mathnormal"
                            }}>{`A`}</span></span><span parentName="span" {...{
                            "style": {
                              "top": "-4.0879em"
                            }
                          }}><span parentName="span" {...{
                              "className": "pstrut",
                              "style": {
                                "height": "3.151em"
                              }
                            }}></span><span parentName="span" {...{
                              "className": "mord cd-vert-arrow"
                            }}><span parentName="span" {...{
                                "className": "sizing reset-size6 size3 mtight cd-label-left",
                                "style": {
                                  "bottom": "0.8em"
                                }
                              }}><span parentName="span" {...{
                                  "className": "mord mtight"
                                }}><span parentName="span" {...{
                                    "className": "mord mathnormal mtight"
                                  }}>{`b`}</span></span></span><span parentName="span" {...{
                                "className": "mord"
                              }}><span parentName="span" {...{
                                  "className": "delimsizing mult"
                                }}><span parentName="span" {...{
                                    "className": "vlist-t vlist-t2"
                                  }}><span parentName="span" {...{
                                      "className": "vlist-r"
                                    }}><span parentName="span" {...{
                                        "className": "vlist",
                                        "style": {
                                          "height": "1.151em"
                                        }
                                      }}><span parentName="span" {...{
                                          "style": {
                                            "top": "-1.966em"
                                          }
                                        }}><span parentName="span" {...{
                                            "className": "pstrut",
                                            "style": {
                                              "height": "2.616em"
                                            }
                                          }}></span><span parentName="span" {...{
                                            "className": "delimsizinginner delim-size1"
                                          }}><span parentName="span">{`↓`}</span></span></span><span parentName="span" {...{
                                          "style": {
                                            "top": "-2.558em"
                                          }
                                        }}><span parentName="span" {...{
                                            "className": "pstrut",
                                            "style": {
                                              "height": "2.616em"
                                            }
                                          }}></span><span parentName="span" {...{
                                            "style": {
                                              "height": "0.616em",
                                              "width": "0.6667em"
                                            }
                                          }}><svg parentName="span" {...{
                                              "xmlns": "http://www.w3.org/2000/svg",
                                              "width": "0.6667em",
                                              "height": "0.616em",
                                              "style": {
                                                "width": "0.6667em"
                                              },
                                              "viewBox": "0 0 666.67 616",
                                              "preserveAspectRatio": "xMinYMin"
                                            }}><path parentName="svg" {...{
                                                "d": "M312 0 H355 V616 H312z M312 0 H355 V616 H312z"
                                              }}></path></svg></span></span><span parentName="span" {...{
                                          "style": {
                                            "top": "-3.166em"
                                          }
                                        }}><span parentName="span" {...{
                                            "className": "pstrut",
                                            "style": {
                                              "height": "2.616em"
                                            }
                                          }}></span><span parentName="span" {...{
                                            "className": "delimsizinginner delim-size1"
                                          }}><span parentName="span">{`⏐`}</span></span></span></span><span parentName="span" {...{
                                        "className": "vlist-s"
                                      }}>{`​`}</span></span><span parentName="span" {...{
                                      "className": "vlist-r"
                                    }}><span parentName="span" {...{
                                        "className": "vlist",
                                        "style": {
                                          "height": "0.65em"
                                        }
                                      }}><span parentName="span"></span></span></span></span></span></span><span parentName="span" {...{
                                "className": "sizing reset-size6 size3 mtight cd-label-right",
                                "style": {
                                  "bottom": "0.8em"
                                }
                              }}><span parentName="span" {...{
                                  "className": "mord mtight"
                                }}></span></span></span></span><span parentName="span" {...{
                            "style": {
                              "top": "-2.2327em"
                            }
                          }}><span parentName="span" {...{
                              "className": "pstrut",
                              "style": {
                                "height": "3.151em"
                              }
                            }}></span><span parentName="span" {...{
                              "className": "mord mathnormal",
                              "style": {
                                "marginRight": "0.07153em"
                              }
                            }}>{`C`}</span></span></span><span parentName="span" {...{
                          "className": "vlist-s"
                        }}>{`​`}</span></span><span parentName="span" {...{
                        "className": "vlist-r"
                      }}><span parentName="span" {...{
                          "className": "vlist",
                          "style": {
                            "height": "1.6062em"
                          }
                        }}><span parentName="span"></span></span></span></span></span><span parentName="span" {...{
                    "className": "arraycolsep",
                    "style": {
                      "width": "0.25em"
                    }
                  }}></span><span parentName="span" {...{
                    "className": "arraycolsep",
                    "style": {
                      "width": "0.25em"
                    }
                  }}></span><span parentName="span" {...{
                    "className": "col-align-c"
                  }}><span parentName="span" {...{
                      "className": "vlist-t vlist-t2"
                    }}><span parentName="span" {...{
                        "className": "vlist-r"
                      }}><span parentName="span" {...{
                          "className": "vlist",
                          "style": {
                            "height": "3.6992em"
                          }
                        }}><span parentName="span" {...{
                            "style": {
                              "top": "-5.9268em"
                            }
                          }}><span parentName="span" {...{
                              "className": "pstrut",
                              "style": {
                                "height": "3.151em"
                              }
                            }}></span><span parentName="span" {...{
                              "className": "mrel x-arrow"
                            }}><span parentName="span" {...{
                                "className": "vlist-t vlist-t2"
                              }}><span parentName="span" {...{
                                  "className": "vlist-r"
                                }}><span parentName="span" {...{
                                    "className": "vlist",
                                    "style": {
                                      "height": "0.9234em"
                                    }
                                  }}><span parentName="span" {...{
                                      "style": {
                                        "top": "-3.322em"
                                      }
                                    }}><span parentName="span" {...{
                                        "className": "pstrut",
                                        "style": {
                                          "height": "2.7em"
                                        }
                                      }}></span><span parentName="span" {...{
                                        "className": "sizing reset-size6 size3 mtight cd-arrow-pad"
                                      }}><span parentName="span" {...{
                                          "className": "mord mtight"
                                        }}><span parentName="span" {...{
                                            "className": "mord mathnormal mtight"
                                          }}>{`a`}</span></span></span></span><span parentName="span" {...{
                                      "className": "svg-align",
                                      "style": {
                                        "top": "-2.689em"
                                      }
                                    }}><span parentName="span" {...{
                                        "className": "pstrut",
                                        "style": {
                                          "height": "2.7em"
                                        }
                                      }}></span><span parentName="span" {...{
                                        "className": "hide-tail",
                                        "style": {
                                          "height": "0.522em",
                                          "minWidth": "3em"
                                        }
                                      }}><svg parentName="span" {...{
                                          "xmlns": "http://www.w3.org/2000/svg",
                                          "width": "400em",
                                          "height": "0.522em",
                                          "viewBox": "0 0 400000 522",
                                          "preserveAspectRatio": "xMaxYMin slice"
                                        }}><path parentName="svg" {...{
                                            "d": "M0 241v40h399891c-47.3 35.3-84 78-110 128\n-16.7 32-27.7 63.7-33 95 0 1.3-.2 2.7-.5 4-.3 1.3-.5 2.3-.5 3 0 7.3 6.7 11 20\n 11 8 0 13.2-.8 15.5-2.5 2.3-1.7 4.2-5.5 5.5-11.5 2-13.3 5.7-27 11-41 14.7-44.7\n 39-84.5 73-119.5s73.7-60.2 119-75.5c6-2 9-5.7 9-11s-3-9-9-11c-45.3-15.3-85\n-40.5-119-75.5s-58.3-74.8-73-119.5c-4.7-14-8.3-27.3-11-40-1.3-6.7-3.2-10.8-5.5\n-12.5-2.3-1.7-7.5-2.5-15.5-2.5-14 0-21 3.7-21 11 0 2 2 10.3 6 25 20.7 83.3 67\n 151.7 139 205zm0 0v40h399900v-40z"
                                          }}></path></svg></span></span><span parentName="span" {...{
                                      "style": {
                                        "top": "-2.578em"
                                      }
                                    }}><span parentName="span" {...{
                                        "className": "pstrut",
                                        "style": {
                                          "height": "2.7em"
                                        }
                                      }}></span><span parentName="span" {...{
                                        "className": "sizing reset-size6 size3 mtight cd-arrow-pad"
                                      }}><span parentName="span" {...{
                                          "className": "mord mtight"
                                        }}></span></span></span></span><span parentName="span" {...{
                                    "className": "vlist-s"
                                  }}>{`​`}</span></span><span parentName="span" {...{
                                  "className": "vlist-r"
                                }}><span parentName="span" {...{
                                    "className": "vlist",
                                    "style": {
                                      "height": "0.122em"
                                    }
                                  }}><span parentName="span"></span></span></span></span></span></span><span parentName="span" {...{
                            "style": {
                              "top": "-4.0879em"
                            }
                          }}><span parentName="span" {...{
                              "className": "pstrut",
                              "style": {
                                "height": "3.151em"
                              }
                            }}></span></span><span parentName="span" {...{
                            "style": {
                              "top": "-2.2327em"
                            }
                          }}><span parentName="span" {...{
                              "className": "pstrut",
                              "style": {
                                "height": "3.151em"
                              }
                            }}></span><span parentName="span" {...{
                              "className": "mrel x-arrow"
                            }}><span parentName="span" {...{
                                "className": "vlist-t"
                              }}><span parentName="span" {...{
                                  "className": "vlist-r"
                                }}><span parentName="span" {...{
                                    "className": "vlist",
                                    "style": {
                                      "height": "0.528em"
                                    }
                                  }}><span parentName="span" {...{
                                      "style": {
                                        "top": "-2.862em"
                                      }
                                    }}><span parentName="span" {...{
                                        "className": "pstrut",
                                        "style": {
                                          "height": "2.334em"
                                        }
                                      }}></span><span parentName="span" {...{
                                        "className": "cd-arrow-pad"
                                      }}></span></span><span parentName="span" {...{
                                      "className": "svg-align",
                                      "style": {
                                        "top": "-2.417em"
                                      }
                                    }}><span parentName="span" {...{
                                        "className": "pstrut",
                                        "style": {
                                          "height": "2.334em"
                                        }
                                      }}></span><span parentName="span" {...{
                                        "className": "hide-tail",
                                        "style": {
                                          "height": "0.334em",
                                          "minWidth": "3em"
                                        }
                                      }}><svg parentName="span" {...{
                                          "xmlns": "http://www.w3.org/2000/svg",
                                          "width": "400em",
                                          "height": "0.334em",
                                          "viewBox": "0 0 400000 334",
                                          "preserveAspectRatio": "xMinYMin slice"
                                        }}><path parentName="svg" {...{
                                            "d": "M0 50 h400000 v40H0z m0 194h40000v40H0z\nM0 50 h400000 v40H0z m0 194h40000v40H0z"
                                          }}></path></svg></span></span></span></span></span></span></span></span><span parentName="span" {...{
                          "className": "vlist-s"
                        }}>{`​`}</span></span><span parentName="span" {...{
                        "className": "vlist-r"
                      }}><span parentName="span" {...{
                          "className": "vlist",
                          "style": {
                            "height": "1.6062em"
                          }
                        }}><span parentName="span"></span></span></span></span></span><span parentName="span" {...{
                    "className": "arraycolsep",
                    "style": {
                      "width": "0.25em"
                    }
                  }}></span><span parentName="span" {...{
                    "className": "arraycolsep",
                    "style": {
                      "width": "0.25em"
                    }
                  }}></span><span parentName="span" {...{
                    "className": "col-align-c"
                  }}><span parentName="span" {...{
                      "className": "vlist-t vlist-t2"
                    }}><span parentName="span" {...{
                        "className": "vlist-r"
                      }}><span parentName="span" {...{
                          "className": "vlist",
                          "style": {
                            "height": "3.6992em"
                          }
                        }}><span parentName="span" {...{
                            "style": {
                              "top": "-5.9268em"
                            }
                          }}><span parentName="span" {...{
                              "className": "pstrut",
                              "style": {
                                "height": "3.151em"
                              }
                            }}></span><span parentName="span" {...{
                              "className": "mord mathnormal",
                              "style": {
                                "marginRight": "0.05017em"
                              }
                            }}>{`B`}</span></span><span parentName="span" {...{
                            "style": {
                              "top": "-4.0879em"
                            }
                          }}><span parentName="span" {...{
                              "className": "pstrut",
                              "style": {
                                "height": "3.151em"
                              }
                            }}></span><span parentName="span" {...{
                              "className": "mord cd-vert-arrow"
                            }}><span parentName="span" {...{
                                "className": "sizing reset-size6 size3 mtight cd-label-left",
                                "style": {
                                  "bottom": "0.8em"
                                }
                              }}><span parentName="span" {...{
                                  "className": "mord mtight"
                                }}></span></span><span parentName="span" {...{
                                "className": "mord"
                              }}><span parentName="span" {...{
                                  "className": "delimsizing mult"
                                }}><span parentName="span" {...{
                                    "className": "vlist-t vlist-t2"
                                  }}><span parentName="span" {...{
                                      "className": "vlist-r"
                                    }}><span parentName="span" {...{
                                        "className": "vlist",
                                        "style": {
                                          "height": "1.151em"
                                        }
                                      }}><span parentName="span" {...{
                                          "style": {
                                            "top": "-1.966em"
                                          }
                                        }}><span parentName="span" {...{
                                            "className": "pstrut",
                                            "style": {
                                              "height": "2.616em"
                                            }
                                          }}></span><span parentName="span" {...{
                                            "className": "delimsizinginner delim-size1"
                                          }}><span parentName="span">{`⏐`}</span></span></span><span parentName="span" {...{
                                          "style": {
                                            "top": "-2.559em"
                                          }
                                        }}><span parentName="span" {...{
                                            "className": "pstrut",
                                            "style": {
                                              "height": "2.616em"
                                            }
                                          }}></span><span parentName="span" {...{
                                            "style": {
                                              "height": "0.616em",
                                              "width": "0.6667em"
                                            }
                                          }}><svg parentName="span" {...{
                                              "xmlns": "http://www.w3.org/2000/svg",
                                              "width": "0.6667em",
                                              "height": "0.616em",
                                              "style": {
                                                "width": "0.6667em"
                                              },
                                              "viewBox": "0 0 666.67 616",
                                              "preserveAspectRatio": "xMinYMin"
                                            }}><path parentName="svg" {...{
                                                "d": "M312 0 H355 V616 H312z M312 0 H355 V616 H312z"
                                              }}></path></svg></span></span><span parentName="span" {...{
                                          "style": {
                                            "top": "-3.167em"
                                          }
                                        }}><span parentName="span" {...{
                                            "className": "pstrut",
                                            "style": {
                                              "height": "2.616em"
                                            }
                                          }}></span><span parentName="span" {...{
                                            "className": "delimsizinginner delim-size1"
                                          }}><span parentName="span">{`↑`}</span></span></span></span><span parentName="span" {...{
                                        "className": "vlist-s"
                                      }}>{`​`}</span></span><span parentName="span" {...{
                                      "className": "vlist-r"
                                    }}><span parentName="span" {...{
                                        "className": "vlist",
                                        "style": {
                                          "height": "0.65em"
                                        }
                                      }}><span parentName="span"></span></span></span></span></span></span><span parentName="span" {...{
                                "className": "sizing reset-size6 size3 mtight cd-label-right",
                                "style": {
                                  "bottom": "0.8em"
                                }
                              }}><span parentName="span" {...{
                                  "className": "mord mtight"
                                }}><span parentName="span" {...{
                                    "className": "mord mathnormal mtight"
                                  }}>{`c`}</span></span></span></span></span><span parentName="span" {...{
                            "style": {
                              "top": "-2.2327em"
                            }
                          }}><span parentName="span" {...{
                              "className": "pstrut",
                              "style": {
                                "height": "3.151em"
                              }
                            }}></span><span parentName="span" {...{
                              "className": "mord mathnormal",
                              "style": {
                                "marginRight": "0.02778em"
                              }
                            }}>{`D`}</span></span></span><span parentName="span" {...{
                          "className": "vlist-s"
                        }}>{`​`}</span></span><span parentName="span" {...{
                        "className": "vlist-r"
                      }}><span parentName="span" {...{
                          "className": "vlist",
                          "style": {
                            "height": "1.6062em"
                          }
                        }}><span parentName="span"></span></span></span></span></span></span></span></span></span></span></span></div>
    <p>{`This is a formula, `}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><msup parentName="mrow"><mi parentName="msup">{`a`}</mi><mn parentName="msup">{`2`}</mn></msup><mo parentName="mrow">{`+`}</mo><msup parentName="mrow"><mi parentName="msup">{`b`}</mi><mn parentName="msup">{`2`}</mn></msup><mo parentName="mrow">{`=`}</mo><msup parentName="mrow"><mi parentName="msup">{`c`}</mi><mn parentName="msup">{`2`}</mn></msup></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`a^2 + b^2 = c^2`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.8974em",
                  "verticalAlign": "-0.0833em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mord mathnormal"
                }}>{`a`}</span><span parentName="span" {...{
                  "className": "msupsub"
                }}><span parentName="span" {...{
                    "className": "vlist-t"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.8141em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-3.063em",
                            "marginRight": "0.05em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "2.7em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mtight"
                            }}>{`2`}</span></span></span></span></span></span></span></span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2222em"
                }
              }}></span><span parentName="span" {...{
                "className": "mbin"
              }}>{`+`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2222em"
                }
              }}></span></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.8141em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mord mathnormal"
                }}>{`b`}</span><span parentName="span" {...{
                  "className": "msupsub"
                }}><span parentName="span" {...{
                    "className": "vlist-t"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.8141em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-3.063em",
                            "marginRight": "0.05em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "2.7em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mtight"
                            }}>{`2`}</span></span></span></span></span></span></span></span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span><span parentName="span" {...{
                "className": "mrel"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.8141em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mord mathnormal"
                }}>{`c`}</span><span parentName="span" {...{
                  "className": "msupsub"
                }}><span parentName="span" {...{
                    "className": "vlist-t"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.8141em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-3.063em",
                            "marginRight": "0.05em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "2.7em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mtight"
                            }}>{`2`}</span></span></span></span></span></span></span></span></span></span></span></span>{`. It's pretty kool`}</p>
    <h2 {...{
      "id": "code"
    }}>{`Code`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-python",
        "metastring": "live=true",
        "live": "true"
      }}>{`This is a test
`}</code></pre>
    <h2 {...{
      "id": "buttons"
    }}>{`Buttons`}</h2>
    <h3 {...{
      "id": "small-button"
    }}>{`Small Button`}</h3>
    <div style={{
      "display": "block",
      "margin": "0px auto 50px"
    }}>
     <SmallButton text="Lorem Ipsum" mdxType="SmallButton" />
    </div>
    <h3 {...{
      "id": "medium-button"
    }}>{`Medium Button`}</h3>
    <div style={{
      "display": "block",
      "margin": "0px auto 50px"
    }}>
     <MediumButton text="Lorem Ipsum" mdxType="MediumButton" />
    </div>
    <h3 {...{
      "id": "large-button"
    }}>{`Large Button`}</h3>
    <div style={{
      "display": "block",
      "margin": "0px auto 50px"
    }}>
     <LargeButton text="Lorem Ipsum" mdxType="LargeButton" />
    </div>
    <h3 {...{
      "id": "custom-sizing-button"
    }}>{`Custom sizing button`}</h3>
    <div style={{
      "display": "block",
      "margin": "0px auto 50px"
    }}>
     <Button text="Lorem Ipsum" theme={{
        fontSize: '35px',
        width: '300px',
        height: '100px'
      }} mdxType="Button" />
    </div>
    <h2 {...{
      "id": "table"
    }}>{`Table`}</h2>
    <p>{`Colons can be used to align columns.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Tables`}</th>
          <th parentName="tr" {...{
            "align": "center"
          }}>{`Are`}</th>
          <th parentName="tr" {...{
            "align": "right"
          }}>{`Dank`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`col`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`right-aligned`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`$1600`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`is`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`centered`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`$12`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`cool`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`boop`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`$1`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`There must be at least 3 dashes separating each header cell.
The outer pipes (|) are optional, and you don't need to make the
raw Markdown line up prettily. You can also use inline Markdown.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Markdown`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Less`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Pretty`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`Still`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`renders`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`nicely`}</strong></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`3`}</td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "blockquotes"
    }}>{`Blockquotes`}</h2>
    <p>{`Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Blockquotes are very handy in email to emulate reply text.
This line is part of the same quote.`}</p>
    </blockquote>
    <p>{`Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum`}</p>
    <h2 {...{
      "id": "horizontal-rule"
    }}>{`Horizontal Rule`}</h2>
    <p>{`Horizontal Rule`}</p>
    <p>{`Three or more...`}</p>
    <hr></hr>
    <p>{`Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum`}</p>
    <hr></hr>
    <p>{`Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      